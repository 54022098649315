import * as React from 'react';
import {
  Dialog, DialogTitle, DialogProps,
} from '@material-ui/core';

interface SampleDialogProps extends DialogProps {
  open: boolean;
  toggle: () => void;
  children: React.ReactNode;
  title?: string;
}

/**
 *
 * @deprecated 用处不大的封装
 */
export default function SampleDialog (props: SampleDialogProps) {
  const {
    open, toggle,
    children,
    title,
    ...rest
  } = props;

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={toggle}
      fullWidth
      {...rest}
    >
      {
        title && <DialogTitle>{title}</DialogTitle>
      }
      {
        children
      }
    </Dialog>
  );
}