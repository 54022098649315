import React from 'react';
import {
  Box, Card,
  Grid,
  Typography,
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';

export default function VCard(props) {
  const {
    dense = false,
    width = '100%',
    avatar, name, status,
    department, title,
    phone, email = '您的邮箱',
    logo = '/images/logo.png',
    tenantName, address,
  } = props;
  // console.log('propspropsprops');
  // console.log(props);
  const imageURL = status === '未认证' ? '/images/n_certified.png' : '/images/certified.png';
  return (
    <Card
      style={{ position: 'relative', height: 0, paddingBottom: '60%', width, borderRadius: 16 }}
      elevation={4}
    >
      <Grid container style={{ height: '100%', position: 'absolute' }}>
        <Grid item xs={5} style={{
          backgroundImage: `url(${avatar || '/images/honeycomb.svg'})`,
          backgroundSize: avatar ? 'cover' : 'auto',
          backgroundPosition: 'center',
          clipPath: 'polygon(0% 0%, 100% 0%, 65% 100%, 0% 100%)',
        }} />
        <Grid item xs={7}
          style={{
            padding: dense ? '8px' : '16px',
            display: 'flex', flexDirection: 'column'
          }}
        >
          <Box display='flex' alignItems='center' flexDirection='row' justifyContent='space-between' mb={dense ? 1 : 1.5}>
            <Box height={dense ? '36px' : '48px'} width={dense ? '56px' : '72px'}
              mr={1}
              style={{ background: `url(${logo}) no-repeat left`, backgroundSize: 'contain' }}
            />
            <Typography variant='subtitle2' style={{flex: 1}}>{tenantName}</Typography>
            <Box height={dense ? '24px' : '26px'} width={dense ? '24px' : '26px'}
              style={{ background: `url(${imageURL}) no-repeat left`, backgroundSize: 'contain' }}
            />
          </Box>
          <Box >
            <Typography variant='h5' >
              {dense ? name : <strong>{name}</strong>}
            </Typography>
            <Typography variant='overline'>
              {[department, title].filter(i => i).join(' / ')}
            </Typography>
          </Box>
          <Box flex={1} />
          {phone && <Typography gutterBottom={!dense} style={{ fontSize: dense ? 'x-small' : 'small', fontWeight: 400 }} >
            <PhoneIcon style={{ fontSize: 'smaller', marginRight: '8px' }} />
            {phone}
          </Typography>}
          {email && <Typography gutterBottom={!dense} style={{ fontSize: dense ? 'x-small' : 'small', fontWeight: 400 }} >
            <EmailIcon style={{ fontSize: 'smaller', marginRight: '8px' }} />
            {email}
          </Typography>}
          {address && <Typography gutterBottom={!dense} style={{ fontSize: dense ? 'x-small' : 'small', fontWeight: 400 }} >
            <RoomIcon style={{ fontSize: 'smaller', marginRight: '8px' }} />
            {address}
          </Typography>}
        </Grid>
      </Grid>
    </Card>
  );
}