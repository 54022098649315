import { get, identity } from 'lodash';
import * as React from 'react';
import {
  Chip, ChipProps,
  createStyles, makeStyles, Theme
} from '@material-ui/core';

import { useShowContext } from './show';
import Label from './label';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }),
);

type SArrayFieldProps<T> = ChipProps & {
  id?: string;
  label?: string;
  field: string;
  isFolder?: boolean;
  emptyText?: string;
  getKey?: (i: T) => React.Key;
  getLabel?: (i: T) => string;
}
export default function ArrayField<T = unknown>(props: SArrayFieldProps<T>) {
  const classes = useStyles();
  const {
    field, emptyText = '-',
    id, label = field, isFolder,
    getKey = identity,
    getLabel = identity,
    ...chipProps
  } = props;

  const value = useShowContext();
  const data = [].concat(get(value, field, []))
  .map(i => ({ key: getKey(i), label: getLabel(i) }));
  var [t] = useTranslation();
  const filterData = isFolder ? data.filter(res => res.label !== t('YBT-ADMIN.YBT-ADMIN-020104.YBT-ADMIN-02010415')): data;
  return (
    <Label id={id} label={label} >
      <div className={classes.root}>
        {filterData.length
          ? filterData.map(({key, label}) => (<Chip key={key}
            label={label} {...chipProps} size="small"
          />))
          : emptyText
        }
      </div>
    </Label>
  );
}