import * as React from 'react';
import { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
function useCountDown(ms = 60): [() => void, number] {
  const [time, setTime] = React.useState(0);
  const timerRef = React.useRef(null);

  const start = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setTime(ms);
    timerRef.current = setInterval(() => {
      setTime(pre => Math.max(pre - 1, 0) || clearInterval(timerRef.current) || 0);
    }, 1000);
  };

  React.useEffect(() => {
    return () => timerRef.current && clearInterval(timerRef.current);
  }, []);

  return [start, time];
}

export function SendSmsCodeButton({ disabled = false, doSendCode }) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  const [countDown, time] = useCountDown(60);
  const sendCode = () => {
    setLoading(true);
    countDown();
    doSendCode().finally(() => setLoading(false));
  };

  return (
    <Button variant="contained" color="primary"
      style={{ minWidth: '96px' }}
      disabled={loading || disabled || (time > 0)}
      onClick={sendCode}
      startIcon={loading ? <CircularProgress size={18} thickness={2} /> : null}
    >
      {time ? `${time}`+t('login.retry') : t('login.verificationCode')}
    </Button>
  );
}