import React from 'react';

export const UserContext = React.createContext(null);

export const TenantContext = React.createContext(null);

export const TeammessageContext = React.createContext(null);

export const LanuageContext = React.createContext(null);

export const WebSiteContext = React.createContext(null);

export const InnerUserContext = React.createContext(null);


export const DeviceType = React.createContext('desktop');
