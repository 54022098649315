import * as React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

interface SaveButtonProps extends ButtonProps {
  label?: string;
}

export default function SaveButton(props: SaveButtonProps) {
  var [t] = useTranslation();
  const { label =  t('common.confirm'),
    startIcon = <Save />,
    ...restButtonProps
  } = props;

  const {
    formState: { isDirty, isSubmitting, },
  } = useFormContext();

  const disabled = !isDirty;

  const icon = isSubmitting
    ? <CircularProgress size={18} thickness={2} color="inherit" />
    : startIcon;

  return (
    <Button
      type="submit" variant="contained" color="primary"
      disabled={disabled}
      {...restButtonProps}
    >
      {label}
    </Button>
  );
}
