import * as React from 'react';
import { get } from 'lodash';
import {
  Typography, TypographyProps,
} from '@material-ui/core';

import { useShowContext } from './show';
import Label from './label';

type STextFieldProps<T> = TypographyProps & {
  field: string | ((data: T) => string);
  label?: string;
  id?: string;
  emptyText?: string;
}
export default function STextField<T = unknown>(props: STextFieldProps<T>) {
  const {
    id,
    field, label = (typeof field === 'string' ? field : '-'),
    emptyText = '-',
    ...rest
  } = props;

  const data = useShowContext();
  const value = typeof field === 'function'
    ? field(data) || emptyText
    : get(data, field, emptyText);

  return (
    <Label id={id} label={label}>
      <Typography {...rest} style={{color: '#000', marginBottom: 0, padding: '10px 0'}}>
        {typeof value === 'string' ? value : JSON.stringify(value)}
      </Typography>
    </Label>
  );
}