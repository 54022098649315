import React from 'react';
import { get } from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Switch,  SwitchProps,
  FormControl, FormLabel, FormControlLabel,
} from '@material-ui/core';

interface BooleanInputProps extends SwitchProps {
  name: string;
  label?: string;
  title: string;
  backOnChange;
}
export default function BooleanInput (props: BooleanInputProps) {
  const {
    name, label = '', title = '', register, backOnChange = null,
    ...rest
  } = props;
  const { control } = useFormContext();
  let defaultValue = get(control.defaultValuesRef.current, name, true);
  if (name==='acl.watermarkable') {
    defaultValue = false;
  }
  return (
    <Controller control={control} name={name}
      defaultValue={defaultValue}
      render={({onChange, onBlur, value, name }) => (
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">{title}</FormLabel>
          <FormControlLabel
            control={<Switch
              onBlur={onBlur}
              name={name}
              inputRef={(ref) => {register?register(ref):null;}}
              checked={value}
              onChange={e => {
                onChange(e.target.checked);
                if (backOnChange)
                  backOnChange(e.target.checked);
              } }
              {...rest}
            />}
            label={label}
          />
        </FormControl>
      )}
    >
    </Controller>
  );
}